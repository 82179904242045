<!--
 * @Author: LiuXin
 * @Date: 2021-08-16 14:42:26
 * @LastEditors: LiuXin
 * @LastEditTime: 2021-09-14 11:40:39
-->
<template>
  <div class="home_card h100 w100 bcg_w">
    <a-table size="small" v-bind="media" @change="onTableChange"> </a-table>
  </div>
</template>

<script>
import column from '../js/tableColumn.js';
export default {
  emits: ['tableChange'],
  name: 'HomeTable',
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    tableColumns: {
      type: Array,
      default: () => [],
    },
    pagination: {
      type: Object,
      default: () => {
        return { limit: '10', page: '1', pageSize: 10, showTotal: total => `总计${total}条数据`, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '50'] };
      },
    },
    tableSorter: {
      type: Object,
      default: () => {
        return { orderType: 'cost', order: 'desc' };
      },
    },
    tableLoading: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    tableColumns(newVal) {
      this.media.columns = newVal;
      this.media.rowkey = newVal[0]?.dataIndex;
    },

    tableData(newVal) {
      this.media.dataSource = newVal;
    },
    tableLoading(newVal) {
      this.media.loading = newVal;
    },
  },
  data() {
    let pagination;
    if (Object.keys(this.pagination).length == 0) {
      pagination = false;
    } else {
      pagination = this.pagination;
    }
    return {
      column,
      media: {
        columns: [],
        dataSource: this.tableData,
        rowkey: 'id',
        scroll: { y: 216 },
        pagination: pagination,
        loading: this.tableLoading,
        tableSorter: this.tableSorter,
      },
    };
  },
  mounted() {
    this.getTableColumn();
  },
  methods: {
    // 设置表格表头
    getTableColumn() {
      this.media.columns = this.tableColumns || this.column.product;
    },
    onTableChange(pagination, filters, sorter) {
      this.media.pagination.page = pagination.current.toString();
      this.media.pagination.limit = pagination.pageSize.toString();
      this.media.pagination.pageSize = pagination.pageSize;
      this.media.tableSorter.orderType = sorter.field;
      this.media.tableSorter.order = sorter.order == 'ascend' ? 'asc' : 'desc';
      this.$emit('tableChange', this.media.pagination, this.media.tableSorter);
    },
  },
};
</script>

<style scoped lang="less">
.home_table {
  height: 200px;
  .consume_table {
    padding: 0 10px;
    overflow: auto;
  }
}
</style>
