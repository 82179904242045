/*
 * @Author: zhangruifeng
 * @Date: 2021-03-04 17:11:04
 * @LastEditors: chenxing
 * @LastEditTime: 2021-05-12 11:59:12
 */
import axios from '@/utils/axios';

const apiUrl = {
    /**********************客户列表**************************************/

    customerList: '/sys/customers/getPage',
    customerDistinctList: '/sys/customers/getListByGroupCompanyName',
    customerSave: '/sys/customers/save',
    customerUpdate: '/sys/customers/update',

    /***********************产品列表**************************************/
    productList: '/sys/product/list',
    productSave: '/sys/product/save',
    productUpdate: '/sys/product/update',
    /************************产品列表*************************************/
    projectSave: '/sys/project/save', //新增产品
    projectUpdate: '/sys/project/update', //更改产品


    /************************产品关联信息查询*******************************/
    projectRelationlist: '/sys/projectRelateion/list',
    projectRelationSave: '/sys/projectRelateion/save',
    /************************开户主体信息查询*******************************/
    invoiceName: '/sys/customers/getInvoiceNameListByChannel',
    /************************产品公司列表查询*******************************/
    customersList: '/sys/customers/list',
    customersListByChannel: '/sys/customers/getListByChannel',
    customerGroupList: '/sys/customers/getPageGroupCustomer'
};

//获取客户列表的方法
export function getCustomerDistinctListData(params) {
    return axios.post(apiUrl.customerDistinctList, params);
}

export function getCustomerListData(params) {
    return axios.post(apiUrl.customerList, params);
}

export function getCustomerListDataInGroup(params) {
    return axios.post(apiUrl.customerGroupList, params);
}
//新增客户信息的方法
export function saveCustomerData(params) {
    return axios.post(apiUrl.customerSave, params);
}
//更新客户信息的方法
export function updateCustomerData(params) {
    return axios.post(apiUrl.customerUpdate, params);
}


/****************************产品方法*******************************************/
//获取产品列表的方法(需要客户的id)
export function getProductListData(params) {
    return axios.post(apiUrl.productList, params);
}
// 新增产品
export function saveProduct(params) {
    return axios.post(apiUrl.productSave, params);
}
//更新产品
export function updateProduct(params) {
    return axios.post(apiUrl.productUpdate, params);
}

/*****************************产品方法*******************************************/

//新增产品的方法
export function saveProjectData(params) {
    return axios.post(apiUrl.projectSave, params);
}

// 产品分配产品
export function updateProjectData(params) {
    return axios.post(apiUrl.projectUpdate, params);
}

// 产品关联信息
export function getProjectRelationData(projectCode) {
    return axios.get(apiUrl.projectRelationlist + `?projectCode=${projectCode}`);
}

export function getProjectRelationSave(params) {
    return axios.post(apiUrl.projectRelationSave, params);
}
// 获取开户主体列表
export function getInvoiceNameList(params) {
    return axios.post(apiUrl.invoiceName, params);
}
// 获取产品公司列表
export function getCustomersListList(params) {
    return axios.post(apiUrl.customersList, params);
}
// 根据渠道获取产品公司列表
export function getCustomersByChannelList(params) {
    return axios.post(apiUrl.customersListByChannel, params);
}
