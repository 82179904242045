<!--
 * @Author: Yran
 * @Date: 2021-09-10 17:50:33
 * @LastEditors: LiuXin
 * @LastEditTime: 2021-09-26 17:02:00
-->
<template>
  <div class="consume_trend_wrapper w100">
    <!-- chart图容器 -->
    <template v-for="c in children" :key="c">
      <component :is="c.component" v-bind="c"></component>
    </template>
  </div>
</template>

<script>
import DetailChart from './components/DetailChart.vue';

export default {
  name: '',
  components: { DetailChart },
  props: {
    children: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped lang="less">
.consume_trend_wrapper {
  display: flex;
  & > * {
    flex: 1;
    background-color: white;
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}
</style>
