<!--
 * @Author: Yran
 * @Date: 2021-09-10 17:50:15
 * @LastEditors: LiuXin
 * @LastEditTime: 2021-10-21 18:28:31
-->
<template>
  <div class="w100 h100 trend_detail_chart">
    <!-- 模块顶部 -->
    <p class="module_title">
      <span>
        <span class="title_content pr15"> {{ $attrs.moduleTitle || '消耗趋势' }} </span>
      </span>
      <span class="select_group">
        <a-select v-model:value="chartFilterData.filter1" :options="$attrs.selectOptions1" v-bind="$filterOptions" @change="filterChange"> </a-select>
        <a-select v-model:value="chartFilterData.filter2" :options="$attrs.selectOptions2" v-bind="$filterOptions" @change="filterChange"> </a-select>
      </span>
    </p>
    <div class="chart_wrapper w100 h100">
      <a-spin :spinning="tableDatas.chartsLoading">
        <home-chart v-show="echartsOptions.series?.[0]?.data.length > 0" :chart-option="echartsOptions"></home-chart>
        <div v-if="!echartsOptions.series?.[0]?.data.length > 0" class="h100 flexCenter"><a-empty /></div>
      </a-spin>
    </div>
  </div>
</template>

<script>
import HomeChart from '../../HomeChart.vue';
export default {
  name: 'TrendChart',
  components: { HomeChart },
  inject: ['tableDatas', 'chartFilterData'],
  props: { echartsOptions: { type: Object, default: () => {} } },
  data() {
    return {
      moduleTitle: '消耗趋势',
    };
  },
  methods: {
    /** 筛选指标变化
     * @param {*}
     * @return {*}
     */
    filterChange() {
      this.chartFilterData.change = new Array();
    },
  },
};
</script>

<style scoped lang="less">
.trend_detail_chart {
  display: flex;
  flex-direction: column;
}
</style>
<style lang="less">
.trend_detail_chart {
  .chart_wrapper {
    flex-grow: 1;
    .ant-spin-nested-loading {
      height: 100%;
      .ant-spin-container {
        height: 100%;
      }
    }
  }
}
</style>
